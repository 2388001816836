import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Layout from '../../components/layout';
import SEO from '../../components/seo';
import FluidImages from '../../components/fluid-images';

const LosGatosPhotos = () => {
  const source = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(jpeg)|(png)/" }
          relativeDirectory: { eq: "los-gatos" }
        }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 310, maxHeight: 232) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
                originalName
              }
            }
          }
        }
      }
    }
  `);
  return (
    <Layout>
      <SEO description="Photos of our Los Gatos care home." title="Los Gatos Photos" />
      <h1>Los Gatos</h1>
      <div className="grid-three">
        <FluidImages source={source} />
      </div>
    </Layout>
  );
};

export default LosGatosPhotos;
