import React from 'react';
import Img from 'gatsby-image';
import createAltString from '../helpers/create-alt-string';

const FluidImages = ({ source }) =>
  source.allFile.edges.map((img) => {
    const {
      fluid,
      fluid: { originalName },
    } = img.node.childImageSharp;
    const alt = createAltString(originalName);
    return <Img key={originalName} alt={alt} fluid={fluid} />;
  });

export default FluidImages;
